import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.initializeFilterOption()
  }

  initializeFilterOption() {
    const filterName = this.element.dataset['name']
    const filterValue = this.element.dataset['value']

    const url = new URL(window.location.href)

    if (!this.element.dataset['filterUrl'] || this.element.dataset['filterUrl'].trim() == '') {
      if (url.searchParams.has(filterName, filterValue)) {
        url.searchParams.delete(filterName, filterValue)
      } else {
        if(this._isARadio()) {
          url.searchParams.delete(filterName)
        }
        url.searchParams.append(filterName, filterValue)
      }
      
      this.element.setAttribute('data-filter-url', this._removePaginationParam(url.href))
    }
  }

  onClick() {
    if(this._isACheckbox()) {
      this.element.querySelector('.content-filters-option').classList.toggle('hide')
      this.element.querySelector('.content-filters-option-checked').classList.toggle('hide')
    } else {
      const checkedImg = this.element.querySelector('.content-filters-option-checked')
      const uncheckedImg = this.element.querySelector('.content-filters-option')
      let checkedEvent = false
      if(checkedImg.classList.contains('hide')) {
        checkedEvent = true
      }
      
      document.querySelectorAll(`div[data-name='${this.element.dataset['name']}']`).forEach(optionWrapper => {
        optionWrapper.querySelector('.content-filters-option').classList.remove('hide')
        optionWrapper.querySelector('.content-filters-option-checked').classList.add('hide')
      })

      if(checkedEvent) {
        uncheckedImg.classList.add('hide')
        checkedImg.classList.remove('hide')
      } else {
        checkedImg.classList.add('hide')
        uncheckedImg.classList.remove('hide')
      }
    }

    // refresh only for desktop, not for tablet and mobile
    if (window.innerWidth >= 1024) {
      window.location = this._removePaginationParam()
    }
  }

  _removePaginationParam(href) {
    const url = new URL(href || this.element.dataset['filterUrl'])

    url.searchParams.delete("page")

    return url.href
  }

  _isACheckbox() {
    return this.element.dataset['type'] === 'checkbox'
  }

  _isARadio() {
    return this.element.dataset['type'] === 'radio'
  }
}
